@tailwind base;
@tailwind components;
@tailwind utilities;


.bgimg {
  position: relative;
  overflow: hidden;
  background-color: rgba(0,0,0,0.2);
  z-index: 30;
}

.bgimg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://d6pltnqzug32h.cloudfront.net/living3.png');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.bgimg .content {
  position: relative;
  z-index: 1; /* Ensures content is above the background */
}


@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Pattaya&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Instrument+Serif:ital@0;1&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Pattaya&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Fjalla+One&family=Instrument+Serif:ital@0;1&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Pattaya&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=DM+Serif+Display:ital@0;1&family=Fjalla+One&family=Instrument+Serif:ital@0;1&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Pattaya&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Yeseva+One&display=swap');

.caudex {
  font-family: "Caudex", serif;
  font-style: normal;
}

.dmserif {
  font-family: "DM Serif Display", serif;
}


.instrument {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.instrument-italic {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-style: italic;
}


.pattaya {
  font-family: "Pattaya", sans-serif;
  font-weight: 400;
  font-style: normal;
}


/* hamburger menu button  */



.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke:#dc2626;
  stroke-width:5.5;
  stroke-linecap:round;
}
.ham8 .top {
  stroke-dasharray: 40 160;
}
.ham8 .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}
.ham8 .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}
.ham8.active .top {
  stroke-dashoffset: -64px;
}
.ham8.active .middle {
  /* //stroke-dashoffset: -20px; */
  transform: rotate(90deg);
}
.ham8.active .bottom {
  stroke-dashoffset: -64px;
}



/* hamburger menu button  */


.glass{
  background: rgba( 255, 255, 255, 0.05 );
backdrop-filter: blur( 13.5px );
border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.glassred{
  background: rgba(255, 0, 0, 0.229);
backdrop-filter: blur( 13.5px );
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
/* .bgmc{
  background-image: url("https://d2ehq5aws28ia0.cloudfront.net/bgimage.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
} */


.videocus {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.bgi{
  background-image: url("https://d6pltnqzug32h.cloudfront.net/backgroundweat.jpg");
  background-position: center;
  background-size: cover;
}
