/* Base Styles */
.ivmCarousel__container {
  --curve-height: 150px;
  --v-offset: 100px;
  overflow: hidden; /* Prevents overflow of images */
  padding-top: 20px;
  position: relative;
}

.ivmCarousel__container::before {
  content: "";
  background: white;
  border-radius: 45%;
  display: block;
  height: var(--curve-height);
  left: calc(-1 * var(--v-offset));
  position: absolute;
  right: calc(-1 * var(--v-offset));
  top: calc(-0.6 * var(--curve-height));
  width: calc(100vw + 2 * var(--v-offset));
  z-index: 2;
}


.ivmCarousel__container::after{
  content: "";
  background: white;
  border-radius: 45%;
  display: block;
  height: var(--curve-height);
  left: calc(-1 * var(--v-offset));
  position: absolute;
  right: calc(-1 * var(--v-offset));
  bottom: calc(-0.5 * var(--curve-height));
  width: calc(100vw + 2 * var(--v-offset));
  z-index: 2;

}


.ivmCarousel__subContainer {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  transition-timing-function: linear !important;
}

.ivmCarousel__slide {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 14px;
  margin-right: 14px;
  width: 450px; /* Increased width */
}

.ivmCarousel__image {
  width: 100%; /* Full width of the container */
  height: 450px; /* Fixed height for consistency */
  object-fit: cover; /* Ensures the image fills the container and any overflow is hidden */
}

/* Mobile View */
@media (max-width: 600px) {
  .ivmCarousel__container {
    --curve-height: 150px;
    --v-offset: 100px;
    margin-top: -10px;
  }

  .ivmCarousel__slide {
    gap: 8px;
    margin-left: 6px;
    margin-right: 6px;
    width: 300px; /* Increased width */
  }

  .ivmCarousel__image {
    height: 350px; /* Increased height for mobile */
    width: 100%;
    align-self: stretch;
  }
}
